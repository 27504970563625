<template>
	<div class="content" style="padding-top: 20px;">
		<el-row :gutter="20">
			<el-col :md="24" :lg="6" :xl="6" style="margin-bottom: 1.04vw;">
				<div class="box logintimes">
					<div class="title">登录次数</div>
					<div class="subtitle">Login times</div>
					<div class="times"><span>9</span>次</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="6" :xl="6" style="margin-bottom: 1.04vw;">
				<div class="box onlinetimes">
					<div class="title">在线时长</div>
					<div class="subtitle">Online times</div>
					<div class="times"><span>9</span>小时<span>10</span>分</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="12" :xl="12" style="margin-bottom: 1.04vw;">
				<div class="u-f-item u-f-jsb lt">
					<div class="bg">
						<div class="title">登录时间</div>
						<div class="subtitle">Login times</div>
					</div>
					<div class="timeitem u-f-item" style="flex-wrap:wrap">
						<div class="u-f-item u-f-jsb">
							<div class="dot">
								<div>微信</div>
								<div>2021-05-13 11:22</div>
							</div>
							<div class="tag">正常</div>
						</div>
						<div class="u-f-item u-f-jsb">
							<div class="dot">
								<div>PC端</div>
								<div>2021-05-13 11:22</div>
							</div>
							<div class="tag">正常</div>
						</div>
						<div class="u-f-item u-f-jsb">
							<div class="dot">
								<div>PC端</div>
								<div>2021-05-13 11:22</div>
							</div>
							<div class="tag">正常</div>
						</div>
						<div class="u-f-item u-f-jsb">
							<div class="dot">
								<div>微信</div>
								<div>2021-05-13 11:22</div>
							</div>
							<div class="tag">正常</div>
						</div>
					</div>
					<div class="more" @click="showMore = true">
						更多<i class="el-icon-caret-right"></i>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="u-f-item u-f-jsb action" style="margin-bottom: 1.04vw;">
			<div class="item">
				<div class="icon iconfont icon-guize"></div>
				<div>发送邮件</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-guize"></div>
				<div>发布公告</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-paike"></div>
				<div>发布周程</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-baoxiu"></div>
				<div>发起保修</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-guize"></div>
				<div>发起流程</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-shenpi"></div>
				<div>流程审批</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-wenjian"></div>
				<div>文件储存</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-leimupinleifenleileibie2"></div>
				<div>文件共享</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-kaishexueqi"></div>
				<div>个人档案</div>
				<div>0</div>
			</div>
			<div class="item">
				<div class="icon iconfont icon-kecheng"></div>
				<div>专业化发展</div>
				<div>0</div>
			</div>
		</div>
		<el-row :gutter="20">
			<el-col :md="24" :lg="12" :xl="12" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">记录统计</div>
					</div>
					<div style="padding: 0 15px 15px 15px;" class="u-f-item">
						<VeHistogram :data="chartData" :extend="chartExtend" style="height: 260px;width: 100%;"></VeHistogram>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="12" :xl="12" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">个人汇总</div>
					</div>
					<div style="padding: 0 15px 15px 15px;" class="u-f-item">
						<VeHistogram :data="chartData1" :extend="chartExtend" style="height: 260px;width: 100%;"></VeHistogram>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-dialog title="登录时间" :visible.sync="showMore" width="25%">
			<div class="timeitem">
				<div class="u-f-item u-f-jsb">
					<div class="dot">
						<div>微信</div>
						<div>2021-05-13 11:22</div>
					</div>
					<div class="tag">正常</div>
				</div>
				<div class="u-f-item u-f-jsb">
					<div class="dot">
						<div>PC端</div>
						<div>2021-05-13 11:22</div>
					</div>
					<div class="tag">正常</div>
				</div>
				<div class="u-f-item u-f-jsb">
					<div class="dot">
						<div>微信</div>
						<div>2021-05-13 11:22</div>
					</div>
					<div class="tag">正常</div>
				</div>
				<div class="u-f-item u-f-jsb">
					<div class="dot">
						<div>微信</div>
						<div>2021-05-13 11:22</div>
					</div>
					<div class="tag">正常</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			chartExtend: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					type: 'bar',
					barWidth: 40,
					itemStyle: {
						normal: {
							color: '#58AFFF'
						}
					}
				}
			},
			chartData: {
				columns: ['name', 'values'],
				rows: [
					{ name: '过程性', values: 2 },
					{ name: '选修', values: 5 },
					{ name: '社团', values: 13 },
					{ name: '小组学习', values: 20 },
					{ name: '住宿生', values: 16 },
					{ name: '宿舍', values: 2 },
					{ name: '班级', values: 5 },
					{ name: '教师', values: 13 }
				]
			},
			chartData1: {
				columns: ['name', 'values'],
				rows: [
					{ name: '发起文件收集', values: 2 },
					{ name: '发起数据收集', values: 5 },
					{ name: '发起教研', values: 13 },
					{ name: '发起会议', values: 20 }
				]
			},
			showMore: false,
			loginList: []
		};
	},
	created() {},
	mounted() {},
	methods: {
		
	}
};
</script>

<style lang="scss">
.content {
	.box{
		background-repeat: no-repeat;
		background-size: cover;
		height: 13vw;
		box-sizing: border-box;
		padding-top: 30px;
		.title{
			font-size: 22px;
			padding-left: 20px;
			border-left: 3px solid;
			height: 20px;
			line-height: 20px;
		}
		.subtitle{
			font-size: 17px;
			color: #999;
			margin-top: 8px;
			margin-left: 23px;
		}
		.times{
			text-align: center;
			font-size: 18px;
			margin-top: 1.2vw;
			span{
				font-size: 40px;
				font-weight: bold;
			}
		}
	}
	.logintimes{
		background-image: url('../../assets/image/bg1.png');
		.title{
			border-color: #1F93FF;
		}
		span{
			color: #1F93FF;
		}
	}
	.onlinetimes{
		background-image: url('../../assets/image/bg2.png');
		.title{
			border-color: #4CB88B;
		}
		span{
			color: #4CB88B;
		}
	}
	.lt{
		height: 13vw;
		position: relative;
		background-image: url('../../assets/image/bg3.png');
		background-size: contain;
		background-position: left;
		background-repeat: no-repeat;
		background-color: #fff;
		.bg{
			box-sizing: border-box;
			height: 13vw;
			padding: 28px 50px;
			color: #fff;
			.title{
				font-size: 22px;
				margin-bottom: 7px;
			}
			.subtitle{
				font-size: 17px;
			}
		}
		.timeitem{
			height: 13vw;
			margin-right: 1.2vw;
			width: 64%;
			padding-top: 3vw;
			padding-bottom: 2vw;
		}
		.more{
			position: absolute;
			right: 1.2vw;
			top: 28px;
			color: #999;
			cursor: pointer;
		}
	}
	.action{
		.item{
			background-color: #fff;
			width: 9%;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			padding: 20px;
			.icon{
				color: #1E92FF;
				font-size: 30px;
				margin-bottom: 3px;
			}
			>div:nth-child(2){
				color: #888;
			}
			>div:last-child{
				font-size: 24px;
			}
		}
	}
	.timeitem{
		box-sizing: border-box;
		overflow: hidden;
		>div{
			margin-right: 3%;
			background: #F5F5F5;
			padding: 10px 15px;
			max-width: 47%;
			box-sizing: border-box;
			.dot{
				>div:first-child{
					margin-bottom: 5px;
					font-size: 16px;
				}
				>div:last-child{
					color: #999;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.tag{
				background: #AFD9FF;
				border-radius: 5px;
				color: #1F93FF;
				padding: 5px 10px;
				white-space: nowrap;
				margin-left: 1vw;
			}
		}
	}
	.el-dialog{
		.timeitem{
			height: 400px;
			overflow-y: auto;
			>div{
				margin-bottom: 15px;
			}
		}
	}
}
</style>
