<template>
	<div style="padding-top: 20px;" id="schedule">
		<div class="contents">
			<div class="u-f-item head">
				<el-select v-model="week" placeholder="请选择" style="width: 120px;" @change="weekChange">
					<el-option  v-for="item in weekList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="u-f table" style="border-top: 1px solid #EEEEEE;">
				<div class="th-1 u-f-justify u-f-column"></div>
				<div class="th u-f-justify u-f-column" v-for="(item, index) in attend_day" :key="index">
					<div>{{item.name}}</div>
					<div style="margin-top: 10px;">{{item.date}}</div>
				</div>
			</div>
			<div class="u-f table" v-for="(item,index) in curriculum_time" :key="index">
				<div class="th-1 u-f-justify u-f-column">
					<div>{{item.node}}</div>
					<div>{{item.start}}~{{item.end}} </div>
				</div>
				<div class="th u-f-justify u-f-column" :class="val.class?'active':''" v-for="(val,i) in item.list" :key="i">
					<el-tooltip placement="bottom">
						<div slot="content">
							<div style="border-bottom: 1px dashed #fff;padding-bottom:10px;margin-bottom:10px">班级：{{val.class_name||''}}</div>
							<div>学科：{{val.school_discipline_name||''}}</div>
							<div>教师：{{val.user_name||''}}</div>
						</div>
						<div>
							<div>{{val.class_name||''}}</div>
							<div>{{val.school_discipline_name||''}}</div>
							<div>{{val.user_name||'-'}}</div>
						</div>
					</el-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			checkList: ['学科', '教室', '教师'],
			courseList: [],
			curriculum_time: [],
			attend_day: [],
			courseData: [],
			week:'',
			weekList:[]
		};
	},
	created() {},
	mounted() {
		this.getweekList()
	},
	methods: {
		getweekList() {
			this.$api.setting
				.getweekList({
					semester_id: window.localStorage.getItem('semester_id')
				})
				.then(res => {
					if (res.data.code == 1) {
						let data = res.data.data.list;
						let arr = [];
						for (let i = 0;i < data.length;i++) {
							arr.push({
								name: data[i],
								id: i + 1
							});
						}
						this.weekList = arr;
						this.week = res.data.data.now;
						this.getCourseData()
					}
				});
		},
		weekChange(e) {
			this.getCourseData();
		},
		// 获取课表数据
		getCourseData(){
			this.$api.teachers.teacherCourse({teacher_id: this.$route.query.id, week:this.week}).then(res=>{
				if(res.data.code==1){
					this.attend_day = res.data.data.attend_day;
					let curriculum_time = res.data.data.curriculum_time;
					let list = [];
					if(res.data.data.data){
						list = res.data.data.data
					}
					for(let i=0;i<curriculum_time.length;i++){
						this.$set(curriculum_time[i], 'list', [])
						for(let j=0;j<this.attend_day.length;j++){
							curriculum_time[i].list.push({xq: this.attend_day[j].name, node: curriculum_time[i].node})
							let arr = list.filter(item=>item.attend_day == this.attend_day[j].name && item.node == curriculum_time[i].node);
							if(arr.length){
								curriculum_time[i].list[j].school_discipline_name = arr[0].school_discipline_name;
								curriculum_time[i].list[j].class_name = arr[0].class_name;
								curriculum_time[i].list[j].user_name = arr[0].user_name;
							}
						}
					}
					console.log(curriculum_time)
					this.curriculum_time = curriculum_time;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
	}
};
</script>

<style lang="scss">
#schedule{
	.contents {
		background-color: #ffffff;
		.head {
			padding: 5px 20px;
		}
		.table {
			padding: 0;
			.th-1 {
				width: 8%;
				text-align: center;
				height: 90px;
				border-right: 1px solid #EEEEEE;
				border-bottom: 1px solid #EEEEEE;
			}
			.th {
				width: 13.5%;
				text-align: center;
				height: 90px;
				border-right: 1px solid #EEEEEE;
				border-bottom: 1px solid #EEEEEE;
			}
			.active{
				border: 1px solid #FF6B5A;
				background-color: #EEFAF6;
			}
		}
	}
}
</style>
